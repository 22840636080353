((): void => {
  const gnav = document.getElementById('gnav');
  const ham = document.getElementById('hamburger');
  const hamtxt = document.getElementById('hamburger-text');
  const haminnertxt = hamtxt.innerHTML;
  const hamaftertxt = '閉じる';
  const navopen = [gnav, ham];
  const openClassName = 'is-open';

  function toggleOpen(): void {
    const isOpen = ham.classList.contains(openClassName);
    if (!isOpen) {
      navopen.forEach((i) => i.classList.add(openClassName));
      hamtxt.innerHTML = hamaftertxt;
    } else {
      navopen.forEach((i) => i.classList.remove(openClassName));
      hamtxt.innerHTML = haminnertxt;
    }
  }

  ham.addEventListener('click', toggleOpen, false);
})();
