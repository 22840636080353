((): void => {
  const inner = document.getElementById('modal');

  if (inner) {
    const openButtons: HTMLElement[] = [...document.querySelectorAll<HTMLElement>('.anecdote-col')];
    const closeButton: HTMLElement = document.getElementById('js-modal-close');
    const contents: HTMLElement[] = [...document.querySelectorAll<HTMLElement>('.modal__content')];
    const outer: HTMLElement = document.getElementById('modal__outer');
    const nextBtn: HTMLElement = document.getElementById('nextBtn');
    const prevBtn: HTMLElement = document.getElementById('prevBtn');
    const openClassName = 'is-open';
    const activeClassName = 'is-active';

    function init(): void {
      contents.forEach((content) => {
        content.classList.remove(activeClassName);
      });
    }

    function number(index) {
      let testnum = inner.dataset.current;
      return () => {
        inner.dataset.current = index;
        testnum = inner.dataset.current;
      };
    }

    function toggleOpen(): void {
      const isOpen = outer.classList.contains(openClassName);
      if (isOpen) {
        outer.classList.remove(openClassName);
      } else {
        outer.classList.add(openClassName);
      }
    }

    function next(): void {
      const addnum: number = +inner.dataset.current + 1;
      const isLast = contents.length <= addnum;
      init();

      if (isLast) {
        inner.dataset.current = '0';
        contents[0].classList.add(activeClassName);
      } else {
        inner.dataset.current = `${addnum}`;
        contents[addnum].classList.add(activeClassName);
      }
    }

    function prev(): void {
      const addnum: number = +inner.dataset.current - 1;
      const count: number = contents.length - 1;
      const isFirst = addnum < 0;
      init();

      if (isFirst) {
        inner.dataset.current = `${contents.length}`;
        contents[count].classList.add(activeClassName);
      } else {
        inner.dataset.current = `${addnum}`;
        contents[addnum].classList.add(activeClassName);
      }
    }

    nextBtn.addEventListener('click', next, false);
    prevBtn.addEventListener('click', prev, false);
    closeButton.addEventListener('click', () => {
      outer.classList.remove(openClassName);
    });
    // クリックするものにイベント付与
    openButtons.forEach((openButton, index) => {
      openButton.addEventListener('click', () => {
        toggleOpen();
        init();
        number(index);
        contents[index].classList.add(activeClassName);
      });
    });
  }
})();
